.Blink {
    position: absolute;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    /* border-radius: 50%; */
    /* animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
  }
  /* you can change blinker 1s value to control blinking speed. */
  
  /* @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  } */
  