._addIcon{
    cursor: pointer;
}


/* bottom sheeet styling */
._modal{
    display: flex;
    align-items: center;
    justify-content: center;
}

._paper{
    padding: 2%;
    background-color: white;
    width: 80%;
}
._modalDiv{
    text-align: center;
}

._modalInput{
    width: 100%;
    border: 1px solid gainsboro;
    padding: 3% !important;
    outline:none;
    font-family: 'Ubuntu', sans-serif !important;
}
._modalBtn{
    width: 30%;
    border: none;
    background-color: dodgerblue;
    color: white;
    padding: 2%;
    margin-left: 66%;
    margin-top: 20px;
    outline: none !important;
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif !important;
}
._modalTitle{
    color: dodgerblue;
    font-size: 20px;
    font-family: 'Ubuntu', sans-serif !important;
}


._image{
    width: 60%;
    margin: auto;
    cursor: pointer;
}
._modal-div{
    text-align: center;
}
._imageName{
    text-align: center;
    font-family: 'Ubuntu', sans-serif !important;
}
._logo{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    
    
}

._downloadBtn{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 50px;
    border-radius: 15px !important;
    
}

._shopTag{
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 25px !important;
    
}

._share-button{
    margin: auto;
    
    width: 20px
}
._logoDiv{
    text-align: center;
}
._logoDappy{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.Blink {

    width: 50px;
    height: 50px;
    /* background-color: '#EE5407'; */
    /* justify-content: center; */
    /* align-items: center; */
    position: absolute; 
    /* bottom: 0; */


    /* position: absolute;
    width: 30px;
    height: 30px; */
   
    /* background-color: blue;
    border-radius: 50%;
    animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; */
  }


._userImg{
   
    /* border-radius: 4%; */
    width: 100%;
    height: 159px;
    border-radius: 5%;
    border: 3px solid #ffffff;
    object-fit: cover;

    margin-bottom: 20px;
    margin-top: 22px;

    
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 5px 5px 5px #9b9999; */
    
}

@media screen and (min-width: 992px) {
    ._userImg{
   
        /* border-radius: 4%; */
        width: 100%;
        height: 55vw;
        margin-bottom: 20px;
        margin-top: 22px;
        margin-left: 10px;
        margin-right: 10px;
        object-fit: cover;
        
        /* border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 5px 5px 5px #9b9999; */
        
    }
  }


._buttonDesign{
   
    /* border-radius: 4%; */
    /* width: 80%;
    height: 15vw;
    object-fit: cover; */
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    
    /* box-shadow: 5px 5px 5px #9b9999; */
    
}
._userlogo{
   
    /* border-radius: 4%; */
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    /* box-shadow: 5px 5px 5px #9b9999; */
    
}
._userDiv{
    margin-bottom: 20px;
    margin-top: 0px;
    text-align: center;
    font-family: 'Ubuntu', sans-serif !important;
}
._userDips{
    text-align: center;
    background-color: rgb(34, 90, 138);
    font-family: 'Ubuntu', sans-serif !important;
}
._userName{
    text-align: center;
    font-weight: bold;
    
    width: 100%;
    margin-left: 5px;
    margin-right: 5px; 
    background-color: #e5e5e5;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-family: Arial, Helvetica, sans-serif !important;
   font-size: 24px;
    color: #000000;
    
}
._userDes{
    text-align: center;
    font-weight: bold;
    margin-left: 25px;
    margin-right: 25px; 
    font-family: Arial, Helvetica, sans-serif !important;
    width: 100%;
    font-size: 17px;
    /* margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px; */
    color: #000000;
}
._mailBorder {
    background-color: #e5e5e5;
    cursor: pointer;
  }
._bt

._userLocation{
    text-align: center;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif !important;
    width: 100%;
    font-size: 13px;
    margin-left: 25px;
    margin-right: 25px; 

    /* margin-top: 0px;
    margin-left: 15px;
    margin-right: 15px; */
    color: #343434;
}
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
._userBtn{
    width: 90%;
    margin: 3px;
    border: 1px solid gainsboro;
    background-color: white;
    padding: 1%;
    color: dodgerblue;
    font-weight: bold;
    border-radius: 5px;
    outline: none !important;
}
._btnDiv{
    text-align: center;
}

._social_image_frame{
    width:50px;
    height:50px;
    cursor: pointer;
}

.duration {
    background-color: black;
    color: white;
    padding-left: 4px;
    padding-right: 4px;
    opacity: 0.7;
    border-radius: 5px;
    position: absolute;
    top:65%;
    left:68%;
}

/* ._social_image{
    position: relative;
    height:25px;
    cursor: pointer;
    /* background-color: #009BB8; */
    /* top: 12px;
    filter:opacity(.9) drop-shadow(0 0 0 _green);
    -webkit-filter: opacity(.9) drop-shadow(0 0 0 _green);
    
} */ */
._social_image{
    /* width:90%; */
    /* height:205px;
    width:205px; */
    align-content: center;
    cursor: pointer;
    border-radius: 0%;
}
._green{
    background: rgb(7, 29, 7);
  }
._userNotFoundDiv{
    margin-top: 300px;
    text-align: center;
    font-family: 'Ubuntu', sans-serif !important;
}
._userNotFoundtxt{
    font-size: 30px;
    color: rgb(52, 52, 52);
}
._heading{
    font-family: 'Ubuntu', sans-serif !important;
    text-align: center;
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 20px;
}

._caption{
    text-align: center;
    font-weight: bold;
    font-family: 'Ubuntu', sans-serif !important;
    /* position: relative; */
    cursor: pointer;
    left: 5%;
    top: 15px;
    font-size: 12px;
    color: #8E8E93;
}


.center-image{
    /* display:flex; */
   /* justify-content:center; /* horizontally center */
     align-items:center;  /* vertically center */
    /* height:300px;
    width:300px;
    background:orange; */
  }

  

.button {
    background-color: "#e5e5e5";
  }
  
  .InputContainer{
    position: relative;
    display: inline-block;
  }
  
  .ButtonContainer{
    position: relative;
    /* display: inline-block; */
  }
  
  .dotee{
    position: absolute;
   
    right: 34%;
    /* align-content: center; */
    bottom: 143px;
    /* left:50px; */
    /* top:9px; */
  }


  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.25);
  }
  .modal_content {
    background-color: white;
    position: absolute;
  }
  